import React, { useEffect, useRef } from "react";

const useIsFirstMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export default useIsFirstMount;