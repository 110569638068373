import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import dotenv from "dotenv";
import { SplashScreenProvider } from "./app/Context/LayoutContext";

// import * as _redux from "./redux";
import "./index.css";

dotenv.config();

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store);

ReactDOM.render(
  <SplashScreenProvider>
    <App basename={PUBLIC_URL} />
  </SplashScreenProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
